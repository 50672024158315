<template>
  <v-app class="assinatura">
    <v-main fill-height>
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 md4 pa-2>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step color="#A26C74" :complete="e1 > 1" editable step="1"> Passo 1 </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="formulario">
                    <v-card-text>
                      <v-form>
                        <v-text-field
                        ref="start"
                        tabindex="1"
                        v-model="nome"
                        label="Nome"
                        color="#AF7582"
                        autocomplete="novo-nome"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="2"
                        v-model="funcao"
                        label="Função"
                        color="#AF7582"
                        autocomplete="nova-funcao"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="3"
                        v-model="celular"
                        v-mask="'(##) #####.####'"
                        label="Celular"
                        color="#AF7582"
                        autocomplete="novo-celular"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="4"
                        v-model="telefone"
                        v-mask="'(##) ####.####'"
                        label="Telefone"
                        color="#AF7582"
                        autocomplete="novo-telefone"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="5"
                        v-model="email"
                        label="E-mail"
                        color="#AF7582"
                        autocomplete="novo-email"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="5"
                        v-model="photo"
                        label="Foto"
                        color="#AF7582"
                        autocomplete="novo-photo"
                        type="text"
                        ></v-text-field>
                        
                      </v-form>
                    </v-card-text>
                    <v-btn 
                    ref="copy"
                    class="js-copy mr-1"
                    color="#AF7582"
                    @click.native.prevent
                    data-clipboard-target="#source" large>
                    Copiar
                    </v-btn>
                    <v-btn  class="js-copy-src" color="#AF7582" data-clipboard-target="#source" large  @click="downloadSignature">Baixar Assinatura</v-btn>

                    <!-- <v-btn class="js-copy-src" color="#AF7582" data-clipboard-target="#source" large >Copiar HTML</v-btn> -->
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
          <v-flex xs12 md8 pa-2>
            <!-- AREA DE COPIAR -->
            <v-card class="formulario" height="100%">
              <div class="assinatura_source">
                <div ref="sourceWrapper">
                  <table 
                  id="source"
                  cellpadding="0" 
                  cellspacing="0" 
                  class="tuece"
                  style="background-color:#A26C74;"
                  color="#AF7582"
                  v-if="layoutSelected == 'padrao'"
                  ref="signatureContainer"
                  >
                  <tr>
                    <!-- <th style="background:url(https://assinatura.focusadmpredial.com.br/assets/img/photo.png) bottom right no-repeat; width: 100px;  height:140px;"> -->
                    <th>
                      <div class="employee-photo"><img :src="'./assets/img/employees/' + photo + '.png' " /></div>
                      <!-- <div class="photo-frame" style=" height: 140px; position: relative; "><img src="https://assinatura.focusadmpredial.com.br/assets/img/photo.png" /></div> -->
                    </th>
                    <th width="260px;" class="tuece-style">
                      <span style="font-family: Roboto; font-size: 10pt; font-weight: 600; text-transform: uppercase;">
                        {{ nome || "GEORGE OLIVEIRA" }}
                        </span> <br />
                      <span
                      style="font-family: Roboto; font-size: 9pt; text-transform: uppercase;  
                      ">
                      {{ funcao || "SOCIO DIRETOR"}}</span><br />
                      <hr />
                      <span
                      style="font-family: Roboto; font-size: 8pt; 
                      ">
                      <img class="icon-description" :src="'./assets/img/zap.png'" width="8px;" />  {{ celular || "(85) 98100.7949" }}</span><br />
                      <span style="font-family: Roboto; font-size: 8pt;"><img class="icon-description" :src="'./assets/img/mail.png'" width="8px;" />  {{ email || "focus" }}@focusadmpredial.com.br</span><br />
                      <span style="font-family: Roboto; font-size: 8pt;"><img class="icon-description" :src="'./assets/img/phone.png'" width="8px;" /> {{ telefone || "(85) 3044.7949" }} </span>
                      <span style="font-family: Roboto; font-size: 8pt; "><br />
                      <img class="icon-description" :src="'./assets/img/world.png'" width="8px;" /> www.focusadmpredial.com.br</span>
                      
                    </th>
                    <th 
                    style="padding-left: 20px; right"
                    class="tuece-style"
                    rowspan=""
                    >
                    <img :src="'./assets/img/focus10Anos-300x300.png'" alt="Logo" width="140px;" align="right" /><br /><br /><br />
                          
                    
                    </th>
                    <th >
                      <div class="redes">
                        <p><a href="https://instagram.com/focusadmpredial" target="_blank"><img :src="'./assets/img/inst.png'"  /></a></p>
                        <p><a href="https://facebook.com/focusadmpredial" target="_blank"><img class="redes_img" :src="'./assets/img/face.png'"/></a></p>
                        <p><a href="https://www.google.com/maps/place/Focus+Administra%C3%A7%C3%A3o+Predial/@-3.7503954,-38.5163647,17z/data=!3m1!4b1!4m5!3m4!1s0x7c748e8343b7d4d:0x219e5af499fde8df!8m2!3d-3.7504008!4d-38.514176" target="_blank"><img :src="'./assets/img/maps.png'"/></a></p>
                        
                      </div>
                    </th>
                    <th 
                    style="padding-left: 20px; right"
                    class="tuece-style"
                    rowspan=""
                    >
                    &nbsp;
                    </th>
                  </tr>
                 
                  </table>
                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-snackbar :timeout="1000" v-model="feedback" color="success">
              Copiado!
    </v-snackbar>
  </v-app>
</template>

<script>
  import Clipboard from "clipboard";
  import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';


export default {
  data: () => ({
    feedback: false,
    nome: "",
    funcao: "",
    celular: "",
    telefone:"",
    email: "",
    setor: "",
    url: '/img/',
    photo: "employee",
    e1: 1,

    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail inválido.";
      }
    },
    logos: [
      {
        text: "Logo",
        value: "https://www.focusadmpredial.com.br/wp-content/uploads/2022/10/focus10Anos-300x300.png"
      },
      
      ],
    layout: [
      {
        text: "Layout padrão",
        value: "padrao"
      }
    ],
    layoutSelected: "padrao",
      
    
  }),
  computed: {
    tel1() {
      return `tel:${this.telefone.replace(/ /g, "").replace(/\D/g, "")}`;
    }
  },
  methods: {
  downloadSignature() {
    const signatureContainer = this.$refs.signatureContainer;

    html2canvas(signatureContainer).then(canvas => {
      canvas.toBlob(blob => {
        saveAs(blob, 'signature.png');
      });
    });
  }
},

  mounted() {
    this.$refs.start.focus();

    const assinaturaClipboard = new Clipboard(".js-copy");

    const assinaturaHTMLClipboard = new Clipboard(".js-copy-src", {
      text: () => {
        return this.$refs.sourceWrapper.innerHTML;
      }
    });

    assinaturaClipboard.on("success", () => {
      this.feedback = true;
    });

    assinaturaHTMLClipboard.on("success", () => {
      this.feedback = true;
    });

  },
  props: {
    source: String
  }

}
</script>

<style lang="scss">
$focus: #A26C74;
$primary: $focus;
$copy: #2a3844;
$secondary: #779ec3;
$third: #665ef1;
$purple: $third;
// $blue: $primary;
$black: $copy;

html {
  height: 100%;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  padding: 0;
  background: #fafafa !important;
  color: $copy;
}

.assinatura {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  &_header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin-top: 40px;

    span {
      color: $copy;
      font-family: Soleto-Medium, sans-serif; 
    }
  }
  .logo {
    margin-bottom: -27px; 
  }

  &_source {
    overflow: hidden;
    border: 2px dashed $primary;
    height: 100%;
    display: flex;
    position: relative;
    > div {
      margin: auto;
      min-width: 400px;
    }
  }

  &_step {
    display: flex;

    img {
      width: 100%;
      height: auto;
      margin: auto;
      padding: 4px 4px 0;
    }
  }
  .photo-frame {
    position: relative;
    margin-right: 20px;
    bottom: -18px !important;
    
  } 
  .photo-frame img {
    height: 140px;
  }

  .employee-photo {
    position: relative;
    background: url('../../public/assets/img/photo.png') no-repeat; 
    height: 140px;
    margin-right: 0;
    bottom: -18px;
    // margin-top: -120px;
    
    
  }
  .employee-photo img {
    margin-left: 11px;
    margin-right: 12px;
    height: 123px;
    border: 4px solid #BF999D;
    border-radius: 60% ;
  } 

  .redes {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    height: 170px;
    width: 50px;
    background-color: #BF999D;
    
  }
  .redes a>img {
    margin: 0px;
    height: 25px;
  }
  
  .linha-horizontal {
    height: 100px;
    width: 1000px;
    background-image: url(../assets/img/rodape.png);
  }

  .tuece {
    border-collapse: collapse;
    border-spacing: 0;
    padding-top: 20px;
  }

  .tuece td {
    font-family: Roboto, sans-serif;
    font-size: 7px;
    padding: 0 9px 0 0;
    overflow: hidden;
    word-break: normal;
    font-weight: 500;
  }

  .tuece th {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    word-break: normal;
  }
  .tuece th span {
    font-weight: 500;
  }

  .tuece th p {
    font-weight: 500;
  }

  .tuece .tuece-style {
    text-align: left;
    padding-right: 20px;
    padding-left: 10px;
    color:#fafafa;    
  }
  .tuece .tuece-style span{
    text-decoration: none;  
    
  }
  .tuece .tuece-style hr{
    // border: 1px #ffffff;
    height: 1px;
    width: 70%;
    background-image: linear-gradient(to right, transparent, #ffffff, transparent);   
   
  }

  img {
    margin-right: 0px;
  }
  i.v-icon.material-icons {
    display: none;
  }
  a {
    text-decoration: none;
  }


  
}

</style>
