<template>
  <v-app>
    <v-app-bar
      app
      color="primaryfocus"
      prominent
      dark
      elevate-on-scroll
    >
   
      <div class="d-flex align-center">
        <v-img
          alt="FOCUS LOGO"
          class="shrink pt-6"
          contain min-width="50"
          :src="'./assets/img/focus10Anos-300x300.png'"
          transition="scale-transition"
          max-width="100"
        />

       
      </div>
      <v-spacer></v-spacer>

      <div class="pt-7"><v-toolbar-title>Gerador de Assinatura</v-toolbar-title></div>
      
      <v-spacer></v-spacer>
      <div class="pt-7">
      <!-- <v-btn
        href="https://webmail.uece.br"
        target="_blank"
        text
      >
        <span class="mr-2">Webmail</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
      </div>
      <div class="pt-7">
      <v-btn
        @click="dialog = true"
        text
      >
        <span class="mr-2">Ajuda</span>
        <v-icon>mdi-lifebuoy</v-icon>
      </v-btn>
      </div>
           
    </v-app-bar>
    <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <v-card tile>
      <v-toolbar 
          color="primaryfocus"
          dark
      >
        <v-btn
        icon
        dark
        @click="dialog=false"
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ajuda</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- <v-subheader>Criar uma assinatura do Gmail</v-subheader>  -->
        <v-list
        three-line
        subheader
        >

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Para criar uma assinatura do Webmail
            </v-list-item-title>
            <v-list-item-subtitle>
              Entra em contato com o suporte via whatsapp: <v-icon>mdi-whatsapp</v-icon>85 997380835
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Adicionar ou mudar uma assinatura
            </v-list-item-title>
            <v-list-item-subtitle>
              Você pode incluir até 10.000 caracteres na sua assinatura.
              <br /><br />
            </v-list-item-subtitle>
            <span>
              1. Abra o <a href="https://webmail.focusadmpredial.com.br" target="_blank">Webmail<v-icon>mdi-open-in-new</v-icon></a>.
              </span>
              <span>2. No canto superior direito, clique em Configurações <v-icon>mdi-cog</v-icon> >  <strong>Ver todas as configurações.</strong></span>
              <span>3. Na seção "Assinatura", Cole a assinatura à caixa. Se quiser, você pode formatar a mensagem adicionando uma imagem ou alterando o estilo do texto.</span>
              <span>4. Na parte inferior da página, clique em <strong>Salvar alterações</strong>.</span>
              
          </v-list-item-content>
        </v-list-item> -->
        
        </v-list>
      </v-card-text>
    </v-card>

    </v-dialog>

    <v-main>
      <PlayGround/>
    </v-main>
  </v-app>
</template>

<script>
import PlayGround from './components/PlayGround';

export default {
  name: 'App',

  components: {
    PlayGround,
  },

  data: () => ({
    dialog: false,
  }),
};
</script>
<style lang="css">
.primaryfocus {background: linear-gradient(to right, #A26C74, #AF7582 40%);
font-family: "Soleto-Regular", sans-serif;


}
.theme--light.v-application {
  background: #f6f6f6;
}
a {
  text-decoration: none;
}
</style>

