import Vue from 'vue'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'



require('@/assets/css/fonts.css');
require('@/assets/img/ceara.png');
// require('@/assets/img/*');
require('@/assets/img/uece.png');
require('@/assets/img/rodape.png');

const requireAll = requireContext => requireContext.keys().map(requireContext);
const pngFiles = require.context('@/assets/img/', true, /\.png$/);
requireAll(pngFiles);

Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
